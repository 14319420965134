import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { App } from '@sim-admin-frontends/data-access';

import PaymentEditContainer from '../../components/payments/edit/PaymentEdit.container';

type PaymentsEditPageProps = {
  id: string;
  app?: App;
};

const PaymentsEditPage: FC<RouteComponentProps<PaymentsEditPageProps>> = ({ match }) => {
  const { id, app } = match.params;

  return <PaymentEditContainer id={id} app={app} />;
};

export default PaymentsEditPage;

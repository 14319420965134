import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { FC, useMemo, MouseEvent, Fragment } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import {
  Menu,
  MenuItem,
  Sider,
  Navbar,
  Error,
  TMenuEntry,
  MenuItemWrapper,
} from '@sim-admin-frontends/ui-shared';

import {
  HEADER_HEIGHT_PX,
  SIDER_COLLAPSED_WIDTH_PX,
  SIDER_WIDTH_PX,
} from '../../constants/Constants';
import UserPrefsDropdown from '../header/UserPrefsHeader';
import { createMainMenuItems } from '../menu/MainMenu';
import { SidebarHeader } from '../header/SidebarHeader';
import GlobalWarning from '../global-warning/GlobalWarning';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  overflow: auto;
  flex: 1;
  position: relative;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledMenu = styled(Menu)`
  padding: ${({ theme }) => theme.spaces.spacing8};
  flex: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ErrorWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`;

const Divider = styled.hr`
  margin: ${({ theme }) => theme.spaces.spacing8};
  height: 1px;
  border: 0;
  background-color: ${({ theme }) => theme.colors.coolGray20};
`;

const Layout: FC = ({ children }) => {
  const { t } = useTranslation();
  const menuItems = useMemo(() => createMainMenuItems(t), [t]);
  const theme = useTheme();

  const renderMenuItem = (item: TMenuEntry) => {
    const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
      if (item.disabled) {
        e.preventDefault();
        return;
      }
    };

    const active = (match: any, location: any) => {
      if (item.path === '/') {
        return location.pathname === '/';
      }
      return location.pathname.includes(match?.url);
    };

    return (
      <Fragment key={item.label}>
        <MenuItemWrapper
          as={NavLink}
          exact={item.path === '/'}
          to={generatePath(item.path)}
          onClick={onClick}
          activeStyle={{
            backgroundColor: theme.colors.yellow10,
          }}
          isActive={active}
          disabled={item.disabled}
          data-testid={item.testId}
        >
          <MenuItem {...item} />
        </MenuItemWrapper>
        {item.hasDivider && <Divider />}
      </Fragment>
    );
  };

  return (
    <Wrapper>
      <Sider width={SIDER_WIDTH_PX} collapsedWidth={SIDER_COLLAPSED_WIDTH_PX}>
        <SidebarHeader />
        <StyledMenu items={menuItems} render={renderMenuItem} />
      </Sider>
      <RightWrapper>
        <Navbar headerHeight={HEADER_HEIGHT_PX}>
          <GlobalWarning />
          <UserPrefsDropdown />
        </Navbar>
        <ErrorBoundary
          fallback={() => (
            <ContentWrapper>
              <ErrorWrapper>
                <Error caption={t('error.globalError')} onClick={() => window.location.reload()} />
              </ErrorWrapper>
            </ContentWrapper>
          )}
        >
          <ContentWrapper>{children}</ContentWrapper>
        </ErrorBoundary>
      </RightWrapper>
    </Wrapper>
  );
};

export default Layout;

import {
  getErrorMessage,
  loadingToast,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';

import WhatsappEdit from './WhatsappEdit';
import { TWhatsappFormValues } from '../../../types/TWhatsapp';
import { transformWhatsappValues } from '../../../utils/whatsappUtil';

const TOAST_SHARED_ID = 'toastId1';

const WhatsappEditContainer = () => {
  const { t } = useTranslation();

  const onSubmit = async (values: TWhatsappFormValues) => {
    try {
      loadingToast(t('institutions.createToast.loading'), {
        toastId: TOAST_SHARED_ID,
      });
      const dataForBe = await transformWhatsappValues(values);
      const baseEndpoint = process.env.NX_AWS_BASE_ENDPOINT;
      const res = await fetch(`${baseEndpoint}/whatsapp-send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataForBe),
      });
      const json = await res.json();
      if (json.errors) {
        const { message } = json.errors[0];
        throw new Error(message);
      }
      updateToast(TOAST_SHARED_ID, json.message || t('common.success'), TToastType.SUCCESS);
    } catch (e) {
      updateToast(TOAST_SHARED_ID, getErrorMessage(e), TToastType.ERROR);
      captureException(e);
    }
  };

  return <WhatsappEdit onSubmit={onSubmit} />;
};

export default WhatsappEditContainer;

const ROUTES = {
  home: {
    path: '/',
  },
  login: {
    path: '/login',
  },
  landmarks: {
    path: '/places/landmarks/:placeId',
  },
  institutions: {
    path: '/institutions/:placeId?',
  },
  places: {
    path: '/places',
  },
  landmarkEdit: {
    path: '/places/landmarks/:placeId/edit/:id?',
  },
  placeEdit: {
    path: '/places/edit/:id?',
  },
  placeFeatures: {
    path: '/places/featureFlags/:id',
  },
  placeFeaturesEdit: {
    path: '/places/featureFlags/edit/:placeId?/:featureIndex?',
  },
  institutionsEdit: {
    path: '/institutions/edit/:id?',
  },
  institutionEditPlace: {
    path: '/institutions/institutionEditPlace/:placeId?',
  },
  institutionFeatures: {
    path: '/institutions/featureFlags/:id',
  },
  institutionFeaturesEdit: {
    path: '/institutions/featureFlags/edit/:institutionId?/:featureIndex?',
  },
  users: {
    path: '/users',
  },
  institutionsUsers: {
    path: '/users/institution/:institutionId?',
  },
  placeUsers: {
    path: '/users/place/:placeId?',
  },
  userEdit: {
    path: '/users/edit/:username?',
  },
  institutionUserEdit: {
    path: '/users/institutionUserEdit/:institutionId?',
  },
  tasks: {
    path: '/tasks',
  },
  taskEdit: {
    path: '/tasks/edit/:code?',
  },
  taskEditTwitter: {
    path: '/tasks/editTwitter/:code?',
  },
  taskEditGoogle: {
    path: '/tasks/editGoogle/:code?',
  },
  taskEditYelp: {
    path: '/tasks/editYelp/:code?',
  },
  selectors: {
    path: '/selectors',
  },
  selectorEdit: {
    path: '/selectors/edit/:code?',
  },
  systemSettings: {
    path: '/systemSettings',
  },
  payments: {
    path: '/payments/:type?',
  },
  paymentsEdit: {
    path: '/payments/edit/:id/:app?',
  },
  notificationsEdit: {
    path: '/notifications/:type?',
  },
  influencers: {
    path: '/influencers',
  },
  influencersEdit: {
    path: '/influencers/edit/:id?',
  },
  influencerSpots: {
    path: '/influencers/spots/:influencerId',
  },
  influencerSpotsEdit: {
    path: '/influencers/spots/edit/:influencerId/:id?',
  },
  placeItineraries: {
    path: '/places/itineraries/:placeId/:type?',
  },
  preferenceCards: {
    path: '/preferenceCards',
  },
  whatsapp: {
    path: '/whatsapp',
  },
};

export default ROUTES;

import { TSelectItem } from '@sim-admin-frontends/ui-shared';

export enum MessageRecipientType {
  FILE = 'FILE',
  NUMBER = 'NUMBER',
}

export type TWhatsappFormValues = {
  files?: File[];
  recipientType?: TSelectItem;
  recipient?: string;
  messageType?: TSelectItem;
  content?: string;
  template?: string;
};

export enum MessageType {
  TEXT = 'text',
  TEMPLATE = 'template',
}

export type WhatsAppCsvRequest = {
  csvData?: string;
  messageType?: MessageType;
  messageText?: string;
  templateName?: string;
  languageCode?: string;
  recipient?: string;
};

import { FC } from 'react';
import { TTabPanelTabs, TabPanel } from '@sim-admin-frontends/ui-shared';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';
import { App } from '@sim-admin-frontends/data-access';

import ROUTES from '../../../routing/routes';
import PaymentsViewContainer from './PaymentsView.container';

export const paymentTabs = [App.Simplicity, App.Travelution];

const PaymentsTabs: FC = () => {
  const { setTabIndex, tabIndex } = useTabNavigation(ROUTES.payments.path, paymentTabs);

  const tabs: TTabPanelTabs = [
    {
      content: <PaymentsViewContainer app={App.Simplicity} />,
      label: App.Simplicity,
    },
    {
      content: <PaymentsViewContainer app={App.Travelution} />,
      label: App.Travelution,
    },
  ];

  return <TabPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex} />;
};
export default PaymentsTabs;

import { isFile } from '@sim-admin-frontends/utils-shared';

import {
  MessageRecipientType,
  MessageType,
  TWhatsappFormValues,
  WhatsAppCsvRequest,
} from '../types/TWhatsapp';

const convertBase64 = async (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = (reader.result as string).split(',')[1]; // Remove the data URL prefix
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });

export const transformWhatsappValues = async (values: TWhatsappFormValues) => {
  const { recipientType, files, recipient, messageType, template, content } = values;
  const valuesForBe: WhatsAppCsvRequest = {};
  const file = files?.[0];

  if (recipientType?.value === MessageRecipientType.FILE && file && isFile(file)) {
    const csvData = await convertBase64(file);
    valuesForBe.csvData = csvData;
  } else if (recipientType?.value === MessageRecipientType.NUMBER && recipient) {
    valuesForBe.recipient = recipient;
  } else {
    throw new Error('No data to send.');
  }

  if (messageType?.value === MessageType.TEMPLATE && template) {
    valuesForBe.messageType = MessageType.TEMPLATE;
    valuesForBe.templateName = template;
  } else if (messageType?.value === MessageType.TEXT && content) {
    valuesForBe.messageType = MessageType.TEXT;
    valuesForBe.messageText = content;
  } else {
    throw new Error('No message type to send.');
  }

  return valuesForBe;
};

import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  App,
  PaymentTransactionsByProcessingStatusFilterInput,
  ProcessingStatus,
  useInfiniteSAPaymentTransactionsQuery,
} from '@sim-admin-frontends/data-access';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';
import { Error, SpinnerWrapper, TDatesRange, TSelectItem } from '@sim-admin-frontends/ui-shared';
import { endOfDay, startOfDay, subDays } from 'date-fns';

import {
  createTableColumns,
  getPaymentsData,
  transformDateRangeValues,
} from '../../../utils/paymentsUtils';
import PaymentsView from './PaymentsView';

const PAYMENTS_PAGE_SIZE = 30;

type Props = {
  app: App;
};

const PaymentsViewContainer: FC<Props> = ({ app }) => {
  const { t } = useTranslation();
  const [currentProcessingStatus, setCurrentProcessingStatus] = useState<ProcessingStatus>(
    ProcessingStatus.New,
  );

  const initialDateFilterValues = {
    datetimeFrom: startOfDay(subDays(new Date(), 30)).toISOString(),
    datetimeTo: endOfDay(new Date()).toISOString(),
  };

  const [filter, setFilter] =
    useState<PaymentTransactionsByProcessingStatusFilterInput>(initialDateFilterValues);

  const { data, isLoading, hasNextPage, fetchNextPage, isError, isFetchingNextPage, refetch } =
    useInfiniteSAPaymentTransactionsQuery(currentProcessingStatus, filter, PAYMENTS_PAGE_SIZE, app);

  const onDateFilterChange = useCallback((dateValues: TDatesRange) => {
    setFilter({
      datetimeFrom: startOfDay(dateValues.dateFrom ?? new Date()).toISOString(),
      datetimeTo: endOfDay(dateValues.dateTo ?? new Date()).toISOString(),
    });
  }, []);

  const onStatusFilterChange = useCallback((option: readonly TSelectItem[] | null) => {
    setCurrentProcessingStatus((option?.[0].value ?? ProcessingStatus.New) as ProcessingStatus);
  }, []);

  const columns = createTableColumns(t, app);

  const { currentPageIndex, onPreviousPress, onNextPress } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const itemsCount =
    data?.pages[currentPageIndex].paymentTransactionsByProcessingStatus.paymentTransactions
      .length ?? 0;

  const paymentTransactions = getPaymentsData(
    data?.pages[currentPageIndex].paymentTransactionsByProcessingStatus.paymentTransactions ?? [],
  );

  return (
    <PaymentsView
      tablePageSize={PAYMENTS_PAGE_SIZE}
      loading={isLoading || isFetchingNextPage}
      itemsCount={itemsCount}
      pageIndex={currentPageIndex}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      hasNextPage={!!hasNextPage}
      columns={columns}
      data={paymentTransactions}
      onDateFilterChange={onDateFilterChange}
      initialDateFilterValues={transformDateRangeValues(filter)}
      onStatusFilterChange={onStatusFilterChange}
    />
  );
};

export default PaymentsViewContainer;

import {
  Button,
  FormInput,
  FormSelect,
  FormTextarea,
  FormUpload,
  FormWrapper,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import { isEmpty, isFile } from '@sim-admin-frontends/utils-shared';

import { MessageRecipientType, TWhatsappFormValues, MessageType } from '../../../types/TWhatsapp';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';

const SUPPORTED_FILE_TYPES = ['text/csv'];
const MAX_FILE_SIZE = 5242880;

const schema = (t: TFunction) =>
  Yup.object().shape({
    files: Yup.mixed().when('recipientType', {
      is: (recipientType?: TSelectItem) => recipientType?.value === MessageRecipientType.FILE,
      then: Yup.mixed()
        .test('fileSize', t('whatsapp.edit.fileSize'), (files?: File[]) => {
          const file = files?.[0];
          return !!file && isFile(file) && file.size <= MAX_FILE_SIZE;
        })
        .test('fileType', t('whatsapp.edit.receiverValidation'), (files?: File[]) => {
          const file = files?.[0];
          return !!file && isFile(file) && SUPPORTED_FILE_TYPES.includes(file.type);
        }),
    }),
    recipient: Yup.string()
      .nullable()
      .when('recipientType', {
        is: (recipientType?: TSelectItem) => recipientType?.value === MessageRecipientType.NUMBER,
        then: Yup.string().nullable().required(t('form.fieldRequired')),
      }),
    template: Yup.string()
      .nullable()
      .when('messageType', {
        is: (messageType?: TSelectItem) => messageType?.value === MessageType.TEMPLATE,
        then: Yup.string().nullable().required(t('form.fieldRequired')),
      }),
    content: Yup.string()
      .nullable()
      .when('messageType', {
        is: (messageType?: TSelectItem) => messageType?.value === MessageType.TEXT,
        then: Yup.string().nullable().required(t('form.fieldRequired')),
      }),
  });

type Props = {
  onSubmit: (values: TWhatsappFormValues) => Promise<void>;
};

const WhatsappEdit = ({ onSubmit }: Props) => {
  const { t } = useTranslation();

  const messageTypeOptions = useMemo(
    () =>
      Object.values(MessageType).map((messageTypeOption) => ({
        value: messageTypeOption,
        label: messageTypeOption,
      })),
    [],
  );

  const typeOptions = useMemo(
    () =>
      Object.values(MessageRecipientType).map((typeOption) => ({
        value: typeOption,
        label: typeOption,
      })),
    [],
  );

  const initialValues: TWhatsappFormValues = {
    recipientType: typeOptions[0],
    messageType: messageTypeOptions[0],
  };

  const methods = useForm<TWhatsappFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, control, setValue } = methods;
  const { errors, isSubmitting } = formState;

  const onTypeChange = (typeOption: readonly TSelectItem[] | null) => {
    const typeValue = typeOption?.[0]?.value;
    if (typeValue === MessageRecipientType.FILE) {
      setValue('recipient', undefined, { shouldValidate: true });
    }
    if (typeValue === MessageRecipientType.NUMBER) {
      setValue('files', undefined, { shouldValidate: true });
    }
  };

  const onMessageTypeChange = (typeOption: readonly TSelectItem[] | null) => {
    const typeValue = typeOption?.[0]?.value;
    if (typeValue === MessageType.TEMPLATE) {
      setValue('content', undefined, { shouldValidate: true });
    }
    if (typeValue === MessageType.TEXT) {
      setValue('template', undefined, { shouldValidate: true });
    }
  };

  const [recipientType, messageType] = useWatch({
    name: ['recipientType', 'messageType'],
    control,
  });

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormSelect
            control={control}
            name="recipientType"
            label={t('whatsapp.edit.type')}
            options={typeOptions}
            defaultValue={initialValues.recipientType}
            onChange={onTypeChange}
          />
          {recipientType?.value === MessageRecipientType.FILE && (
            <FormUpload
              control={control}
              name="files"
              dropzoneLabel={t('whatsapp.edit.file')}
              t={t}
              accept={SUPPORTED_FILE_TYPES}
              error={errors.files?.[0]}
            />
          )}
          {recipientType?.value === MessageRecipientType.NUMBER && (
            <FormInput
              label={t('whatsapp.edit.number')}
              {...register('recipient')}
              error={errors.recipient}
            />
          )}
          <FormSelect
            control={control}
            name="messageType"
            label={t('whatsapp.edit.messageType')}
            options={messageTypeOptions}
            defaultValue={initialValues.messageType}
            onChange={onMessageTypeChange}
          />
          {messageType?.value === MessageType.TEXT && (
            <FormTextarea
              label={t('whatsapp.edit.content')}
              {...register('content')}
              rows={3}
              error={errors.content}
            />
          )}
          {messageType?.value === MessageType.TEMPLATE && (
            <FormInput
              label={t('whatsapp.edit.template')}
              {...register('template')}
              error={errors.template}
            />
          )}
          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {t('common.send')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default WhatsappEdit;

import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from '../../components/layout/PageLayout';
import WhatsappEditContainer from '../../components/whatsapp/edit/WhatsappEdit.container';

const WhatsappPage = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <PageHeader
        title={t('whatsapp.title')}
        caption={t('whatsapp.description')}
        testId="Whatsapp#PageHeader"
      />
      <WhatsappEditContainer />
    </PageWrapper>
  );
};

export default WhatsappPage;
